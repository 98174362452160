import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout/Layout";
import { Hero } from "../components/Hero/Hero";
import { Section } from "../components/Section/Section";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { getImage, GatsbyImage } from "gatsby-plugin-image";



const OurWorkItem = ({ data, pageContext, location }: any) => {
	const lang = pageContext.lang;

	const localesData = data.allGallery.nodes[0].locales.find(
		(locale: any) => locale.iso === lang
	);
	const titleData: string = localesData.title;
	const descriptionData: string = localesData.description;
	const contentData: string = localesData.content;

	const galleryImages = data.allGallery.nodes[0].gallery.galleryImages
		.map((img: any, i: number) => {
			//const imageData: any = getImage(img.galleryImage);
			return <img
			key={'our-work '+i}
			src={img.galleryImage.publicURL}
			alt={'replace' + i} />
		});


	return (
		<Layout
			department={data.department}
			location={location}
			lang={lang}
			otherLang={pageContext.otherLang}
			defLang={pageContext.defLang}
			metaInfo={pageContext.metaInfo}
		>
			<div className="page-wrapper">
				<Hero title={titleData} description={descriptionData} />
				<Section >
					<div className="htmlContent" dangerouslySetInnerHTML={{ __html: contentData }}></div>
				</Section>
				<ResponsiveMasonry
					columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
					<Masonry gutter="5px">
						{galleryImages}
					</Masonry>
				</ResponsiveMasonry>
			</div>
		</Layout>
	);
};

export const query = graphql`
query MyQuery($uid: String) {
	allGallery(filter: {uid: {eq: $uid}}) {
	  nodes {
		locales {
		  title
		  description
		  content
		  iso
		}
		imageFile {
		  childImageSharp {
			gatsbyImageData(quality: 90)
		  }
		}
		gallery {
		  galleryImages {
			galleryImage {
			  childImageSharp {
				gatsbyImageData(quality: 90, layout: FULL_WIDTH)
				id
			  }
			  publicURL
			}
		  }
		}
	  }
	}
  }
`

export default OurWorkItem;
